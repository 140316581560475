import FloatingImage from './components/FloatingImage';
import TicketCard from './components/TicketCard';
import Header from './components/Header';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import CardPresentation from './components/CardPresentation';
import './styles/App.css';
import './styles/ContactForm.css';
import './styles/TicketCard.css';
import './styles/ServiceCard.css';
import gsap from 'gsap';
import GdmLogo from './assets/gdmlogo.jpg';
import BzLogo from './assets/bz.jpg';
import CLCLogo from './assets/CLClogo.jpg';
import AJLogo from './assets/ajagencementminilogo.jpg';
import bijoux from './assets/bijoux.jpeg';
import 'animate.css';

import React, { useState } from 'react';
import { FaCog, FaDesktop, FaMobile, FaSearch, FaDatabase, FaWordpress } from 'react-icons/fa'; // Utilisation d'icônes de la bibliothèque react-icons
import ServiceCard from './components/ServiceCard';


import DjangoLogo from './assets/django-logo.png'; // logo Django
import PythonLogo from './assets/logo-python.jpg'; // logo Python
import WordpressLogo from './assets/wordpress.png'; // logo wordpress
import ReactLogo from './assets/logo-react.png'; // logo react
import PhpLogo from './assets/php-logo.png'; // logo Php
import SymfonyLogo from './assets/symfony.png'; // logo Symfony
import projectImage1 from './assets/imageGDM.jpg';
import projectImage2 from './assets/CLC.jpg';
import portfolioImage from './assets/photofolio.png';
import photo3 from './assets/photo3.jpg';

import projectImage3 from './assets/ajagencementminilogo.jpg';
import fond from './assets/fond.png';
import linkedin from './assets/linkedin.png';
import { Col, Container, Row } from 'react-bootstrap'; // Import Bootstrap components

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CompetencePage from './components/CompetencePage';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);



function App() {

  const [selectedFilter, setSelectedFilter] = useState('all');

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };


   return (
    <Router>
      <div className="app">
        <Header />

        <Routes>
          <Route path="/competence" element={<CompetencePage />} />
          <Route path="/" element={
            <>
              <Container fluid>
                <div className="background">
                  <Row className="justify-content-between" style={{ justifyContent: 'center' }}>
                    <Col className="column-1">
                      <br /> <br />
                      <div className="linkedin-link">
                        <a href="https://www.linkedin.com/in/maëva-jouan-dev29810" target="_blank" rel="noopener noreferrer">
                          <img src={linkedin} alt="linkedin" className="linked" />
                        </a>
                      </div>
                      <div className="title">
                        <h5>
                          Bonjour, je suis Maëva JOUAN, <br /> Développeur web à Brest
                        </h5>
                      </div>

                      <br />
                    </Col>
                    <Col className="column-2">
                    <div class="iframe-container">
                    <iframe
  width="250"
  height="250"
  src="https://www.youtube.com/embed/kwuzzopobHk?si=Fgciuw-SSBVKtqq7&amp;start=3"
   title="YouTube video player" frameborder="0"
   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
   referrerpolicy="strict-origin-when-cross-origin"
   allowfullscreen>
></iframe>
</div>

                    </Col>
                  </Row>

                </div>
              </Container>
              <div className="modern-divider"></div>

              <div className="links">
                <a href="#forfait">Forfaits</a>
                <a href="#projets">Projets</a>
                <a href="#contact">Contact</a>
              </div>
              <div className="modern-divider" style={{ marginTop:'10px', marginBottom: '-20px'}}></div>

              <br /> <br />
              <div id="first">
                <br /><br /><br /><br /><br />
                <section id="presentation" className="presentation-section large-screen">
                  <div className="present">
                    <CardPresentation />
                    <br />
                    <div id="second"></div>
                    <br /><br /><br /><br />
                  </div>
                </section>
                <br /><br /> <br /><br />
                <br /> <br /><br /> <br />
              </div>

              <div className="modern-divider"></div>
              <div id="forfait"></div>
              <div className="mes-travaux" style={{ position: 'relative', top: '-180px', textAlign: 'center', opacity: '1', fontFamily: 'Quicksand', backgroundColor: 'black', }}>
                <h5 style={{ color: 'white', fontFamily: 'Quicksand', fontSize: '2.2em', justifyContent: 'center', padding: '40px', }}>MES FORFAITS</h5>
                <div
                  className="line"
                  style={{
                    position: 'absolute',
                    left: '50%',
                    bottom: '-80px',
                    width: '0',
                    borderBottom: '2px solid white',
                    opacity: '0.3',
                    transform: 'translateX(-50%)',
                    transition: 'width 2s ease-in-out',
                  }}
                ></div>
              </div>
              <div className="forfait1"></div>
              <br />
              <section id="services" className="services-section">
                <div className="mes-travaux-1">
                <div className="serv">
                  <h5 className="adjustable-heading">CE QUE NOUS POUVONS REALISER ENSEMBLE</h5>
                  </div>
                  <br /> <br /> <br />
                  <div
                    className="line"
                    style={{
                      position: 'absolute',
                      left: '50%',
                      bottom: '15px',
                      width: '0',
                      borderBottom: '2px solid black',
                      opacity: '0.3',
                      transform: 'translateX(-50%)',
                      transition: 'width 2s ease-in-out',
                    }}
                  ></div>
                </div>
                <div className="service-cards-container" style={{ position: 'relative', top: '-5vh' }}>
                  <div className="columns">
                    <div className="column">
                      <ServiceCard
                        title="Développement d'Applications Web"
                        description="Intégrez des fonctionnalités avancées dans vos applications web sur mesure."
                        icon={<FaDesktop />}
                      />
                      <ServiceCard
                        title="Administration Personnalisée"
                        description="Gérez votre site web avec une administration personnalisée selon vos besoins."
                        icon={<FaCog />}
                      />
                      <ServiceCard
                        title="Gestion de Base de Données"
                        description="Assurez la stabilité et la performance de vos bases de données."
                        icon={<FaDatabase />}
                      />
                    </div>
                    <div className="column">
                      <ServiceCard
                        title="Développement de Sites avec CMS"
                        description="Création de sites web gérés avec le système de gestion de contenu WordPress"
                        icon={<FaWordpress />}
                      />
                      <ServiceCard
                        title="Site Ergonomique et Responsive"
                        description="Créez un site web moderne, ergonomique et entièrement responsive."
                        icon={<FaMobile />}
                      />
                      <ServiceCard
                        title="Référencement SEO"
                        description="Optimisez votre site pour les moteurs de recherche et améliorez votre visibilité en ligne."
                        icon={<FaSearch />}
                      />
      </div></div></div>


<br/> <br/> <br/> <br/> <br/> <br/>
</section>


<div className="wave-divider-1" style={{ backgroundColor: 'black', position: 'relative',  zIndex: '0',}}>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="wave-svg" style={{ position: 'absolute', top: '-200px', backgroundColor: 'black', }}>
    <path fill="black" fillOpacity="1" d="M0,96L48,117.3C96,130,192,160,288,160C384,160,480,130,576,117.3C672,105,768,109,864,128C960,149,1056,181,1152,192C1248,203,1344,192,1392,186.7L1440,181.3L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
  </svg><br/><br/> <br/> <br/> <br/>
  <br/><br/>
  </div>

<div id="third">
                <section id="realisations" className="realisations-section" style={{ backgroundColor: 'black' }}>
                  <div className="mes-travaux" style={{ position: 'relative', top: '-180px', textAlign: 'center', opacity: '1', fontFamily: 'Quicksand', backgroundColor: 'black', }}>
                    <h5 style={{ color: 'white', fontFamily: 'Quicksand', fontSize: '2.2em', justifyContent: 'center', padding: '40px', }}>MES REALISATIONS</h5>
                    <br /><br />
                    <div className="links">
                      <a href="#projets" onClick={() => handleFilterChange('vitrine')}>Sites Vitrines</a>
                      <a href="#projets" onClick={() => handleFilterChange('ecommerce')}>E-Commerce</a>
                      <a href="#projets" onClick={() => handleFilterChange('autres')}>Autres</a>
                    </div><br />
                    <div className="line" style={{ position: 'absolute', left: '50%', bottom: '80px', width: '0', borderBottom: '2px solid white', opacity: '0.3', transform: 'translateX(-50%)', transition: 'width 2s ease-in-out', }}></div>

</div>
<div id="projets" className="projets-position">
  <ul>
    {selectedFilter === 'autres' || selectedFilter === 'all' ? (
      <TicketCard
        id="autres-1"
        logoUrl={GdmLogo}
        title="Le Gâteau du Mercredi"
        videoUrl="https://www.youtube.com/embed/_BTIZFlmaXo?si=lP1EsjeQaFOdOW1L"
        description={
          <>
            <br />
            ◘ Filtrer des recettes en excluant les ingrédients allergènes.
            <br /><br />
            ◘ Calculer les glucides pour les diabétiques qui pratiquent
            l'insulinothérapie fonctionnelle.
            <br /><br />
          </>
        }
        imageUrl={projectImage1}
        technologies={['Python', 'Django', 'Bulma', 'PostgreSQL', 'Canva', 'Git']}
        linkText="VOIR LA VIDEO"
        linkUrl="https://drive.google.com/file/d/1G0e4ZUre4QM66knmWLG226HwWkPEikgn/view?usp=drive_link"
      />
    ) : null}

    {selectedFilter === 'autres' || selectedFilter === 'all' ? (
      <TicketCard
        id="autres-1"
        logoUrl={CLCLogo}
        title="Club Lecture Connect"
        videoUrl="https://www.youtube.com/embed/ycTNvdunvAc?si=Dvbwc1fkNr_AD1DW"
        description={
          <>
            <br />◘ Trouver un club de lecture selon <br /> sa localisation,
            ses disponibilités et ses goûts littéraires. <br /> <br /> ◘ Créer
            des réunions de club : <br /> virtuelles avec Google Meet ou bien
            physiques.<br /><br />
          </>
        }
        imageUrl={projectImage2}
        technologies={[
          'PHP',
          'Symfony',
          'MySQL',
          'Leaflet',
          'Google : API Books+Calendar',
          'Bootstrap',
          'Git',
        ]}
        linkText="VOIR LA VIDEO"
        linkUrl="https://drive.google.com/file/d/1KNTh7Kk-0tmeF2rbulJPm82GhSFZqkIo/view?usp=drive_link"
      />
    ) : null}
    {selectedFilter === 'ecommerce' || selectedFilter === 'all' ? (
    <TicketCard
        id="autres-1"
        logoUrl={BzLogo}
        title="Le bon coin Breton"
        videoUrl="https://www.facebook.com/plugins/video.php?height=270&href=https%3A%2F%2Fwww.facebook.com%2F61557524246777%2Fvideos%2F785115157094239%2F&show_text=false&width=560&t=0"
        description={
          <>
            <br />◘ Une version du bon coin version Bretagne.<br /><br />
          </>
        }
        imageUrl={photo3}
        technologies={[
          'Wordpress',
          'php',
          'Leaflet',
        ]}
        linkText="VOIR LA VIDEO"
        linkUrl="https://www.facebook.com/plugins/video.php?height=270&href=https%3A%2F%2Fwww.facebook.com%2F61557524246777%2Fvideos%2F785115157094239%2F&show_text=false&width=560&t=0"/>
   ) : null}
   {selectedFilter === 'ecommerce' || selectedFilter === 'all' ? (
    <TicketCard
        id="autres-1"
        logoUrl={bijoux}
        title="Site de bijoux"
        videoUrl="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F61557524246777%2Fvideos%2F1505814770149490%2F&show_text=false&width=560&t=0"
        description={
          <>
            <br />◘ Un site E commerce de vente en ligne de bijoux, avec gestion des stocks, système de paiement, et système de prise de rdv en magasin.<br /><br />
          </>
        }
        imageUrl={bijoux}
        technologies={[
          'Wordpress',
          'WooCommerce',
          'php',
        ]}
        linkText="VOIR LA VIDEO"
        linkUrl="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F61557524246777%2Fvideos%2F1505814770149490%2F&show_text=false&width=560&t=0"/>
   ) : null}
    {selectedFilter === 'vitrine' || selectedFilter === 'all' ? (
      <TicketCard
        id="vitrine-1"
        logoUrl={AJLogo}
        title={
          <>
            AJ Agencement (site vitrine)
            <br />
          </>
        }
        description={
          <>
            <br />◘ Mettre en valeur le savoir-faire et les projets réalisés.
            <br /><br /><br />
          </>
        }
        imageUrl={projectImage3}
        technologies={['Wordpress', 'Elementor', 'Canva']}
        linkText="VOIR LE SITE"
        linkUrl="https://ajagencement.bzh/"
      />
    ) : null}
  </ul>
  <br /> <br /> <br />
</div>

        </section>
</div>
<div id="fourth">
<div class="modern-divider"></div>
<CompetencePage />
</div>
<div class="modern-divider"></div>





        <div id="contact">
        </div>
         <div className="my-section">
  <ContactForm />
</div>
<div class="modern-divider"></div>
<div className="bas-de-page">
<Footer />
</div>
              </>

            } />
        </Routes>
      </div>

    </Router>
  );
}

export default App;