// CardPresentation.js
import React from 'react';
import { useSpring, animated } from 'react-spring';
import '../styles/CardPresentation.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const CardPresentation = () => {
  const props = useSpring({
    opacity: 1,
    from: { opacity: 0.5 },
  });

  return (
    <animated.div className="card-presentation" style={props}>
      <div className="columns" style={{ display: 'flex', marginBottom: '-40px', marginTop: '-50px' }}>

        {/* La classe col-lg-4 signifie que ces colonnes seront affichées sur les écrans larges (large), sinon, elles seront empilées sur les petits écrans */}
        <div className="column">
          <p1>QUI JE SUIS</p1>
          <p></p>
          <p>Avec sept années d'expérience dans le milieu hospitalier, je me suis forgée des compétences solides en gestion et résolution de problèmes.</p> <p> Guidée par la bienveillance de mon mentor <strong>Nicolas GEORGEMEL</strong>, j'ai suivi le programme {" "}
          <a style={{ color: 'white',
            textDecoration: 'none', // Pour supprimer le soulignement par défaut
            fontWeight: 'bold',
            borderBottom: '1px solid grey', // Couleur du soulignement
            transition: 'color 0.3s, border-color 0.3s', }} href="https://coder-pour-changer-de-vie.com/">
                  "Vivre du Code"
                </a> .</p>
        </div>

        <div className="column">
          <p1>MON APPROCHE</p1>
          <p></p>
          <p>Je suis déterminée à mettre ces compétences au service de la création d'<strong>applications web</strong> et de <strong>sites vitrines</strong>.</p> <p> Mon engagement particulier réside dans une écoute attentive. Au-delà du code, je cherche à <strong>comprendre vos besoins</strong> pour créer les <strong>solutions</strong> adaptées et refléter les valeurs de votre entreprise.</p>
        </div>

        <div className="column col-lg-4">
          <p1>CRÉONS ENSEMBLE</p1>
          <p></p>
          <p>En travaillant ensemble, attendez-vous à une collaboration où l'<strong>écoute</strong> et la <strong>compréhension</strong> jouent un rôle central.</p><p> J'ai hâte de contribuer à vos <strong>projets</strong> de manière personnalisée.</p>
          <p></p>
          <a href="https://calendly.com/jouan-maeva/30min" className="noselect">
            <button>Prenons RDV</button>
</a>
          <p> </p>
          <p>Je vous propose un<strong> RDV gratuit</strong> pour discuter de votre projet.</p>
        </div>
      </div>
    </animated.div>
  );
};

export default CardPresentation;