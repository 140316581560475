import React from 'react';
import '../styles/ServiceCard.css';
const ServiceCard = ({ title, description, icon }) => {
  return (
    <div className="service-card">
      <div className="service-icon">{icon}</div>
      <h8>{title}</h8>
      <p className="description">{description}</p>
    </div>
  );
};

export default ServiceCard;