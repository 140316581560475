// Footer.jsx

import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import '../styles/Footer.css';

function Footer() {
  const footerStyle = {
    background: 'linear-gradient(to bottom, #000000)',
    boxShadow: '0px 4px 8px rgba(255, 255, 255, 0.8)',

  };

  return (
    <Navbar className="white-text" style={footerStyle} expand="lg">
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto">
          <Nav.Link href="/">Accueil</Nav.Link>
          <Nav.Link href="#second">Services</Nav.Link>
          <Nav.Link href="#">Mentions Légales</Nav.Link>

        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Footer;