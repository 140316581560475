import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import '../styles/Header.css';

function Header() {
  const navbarStyle = {
    background: 'linear-gradient(135deg, #2c3e50, #4ca1af)',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.8)',
    fontFamily: 'Quicksand',
    color: 'white',

  };

  const brandStyle = {
    letterSpacing: '2px',
    marginLeft: '10px',
    fontSize: '1.3em',
    fontWeight: '800',
    color: 'white',
    padding: '5px 0',
    display: 'inline-block',
    fontFamily: 'Quicksand',
    opacity: '0.9',
  };

  const navItemStyle = {
  marginLeft: '1rem',
  color: 'white',// Ajustez la valeur de la marge selon vos besoins
};

  return (
    <Navbar collapseOnSelect expand="lg" className="justify-content-between" style={navbarStyle}>
      <Navbar.Brand href="/" style={brandStyle}>
        Maëva JOUAN
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="#first" className="nav-link-bold" style={navItemStyle}>Présentation</Nav.Link>
          <Nav.Link href="#second" className="nav-link-bold" style={navItemStyle}>Services</Nav.Link>
          <Nav.Link href="#third" className="nav-link-bold" style={navItemStyle}>Projets</Nav.Link>
          <Nav.Link href="#fourth" className="nav-link-bold" style={navItemStyle}>Compétences</Nav.Link>
        </Nav>
        <Nav>
          <Button href="#contact" className="contact-button">CONTACT</Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;