import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

function ContactForm() {
  const [state, handleSubmit] = useForm('xoqgdbgy');

  const handleSuccessMessage = () => {
    // Affichez votre message de succès ici
    console.log('Formulaire soumis avec succès !');
  };

  return (
   <div className="contact-form-container">
      <form className="contact-form" onSubmit={handleSubmit}>

        <h7>CONTACT</h7>
        <h4>Vous avez un projet? Envoyez-moi votre message</h4>
        <p></p>

      <label htmlFor="email">Email</label>
      <input id="email" type="email" name="email" />
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      <p></p>

      <label htmlFor="name">Nom</label>
      <input id="name" type="text" name="name" />
       <ValidationError prefix="Nom" field="email" errors={state.errors} />
        <p></p>

      <label htmlFor="message">Message</label>
      <textarea id="message" name="message" />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
        <p></p>
      <button type="submit" disabled={state.submitting} onClick={handleSuccessMessage}>
        Envoyer
      </button>
      <p></p>

      {/* Ajoutez une section pour afficher le message de succès */}
      {state.succeeded && (
        <div className="success-message" style={{ color: 'green', fontSize: '1.2em',}}>
          Votre message a bien été envoyé
        </div>
      )}
    </form>
    </div>
  );
}

export default ContactForm;
