import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Skills.css';
import DjangoLogo from '../assets/django-logo.png'; // logo Django
import PythonLogo from '../assets/python2.jpg'; // logo Python
import WordpressLogo from '../assets/wordpress.png'; // logo wordpress
import ReactLogo from '../assets/logo-react.png'; // logo react
import PhpLogo from '../assets/php-logo.png'; // logo Php
import SymfonyLogo from '../assets/symfony.png'; // logo Symfony
import PostgreSQLLogo from '../assets/postgresql.png'; // logo PostgreSQL
import MySQLLogo from '../assets/MySQL.png'; // logo MySQL
import PyCharmLogo from '../assets/Pycharm.jpg'; // logo Pycharm
import VSCodeLogo from '../assets/vscode.png'; // logo Pycharm
import VuejsLogo from '../assets/vuejs.png'; // logo vuejs
import BulmaLogo from '../assets/bulma.png'; // logo bulma
import Html5Logo from '../assets/HTML5.jpg'; // logo html5
import Css3Logo from '../assets/css3.png'; // logo css3
import JavascriptLogo from '../assets/javascript.png'; // logo css3

const CompetencePage = () => {
  return (
    <div>
      <br /><br /> <br /><br />
      <div className="container-back">
        <br /><br />
         <h5 style={{ fontFamily:'Quicksand', fontSize: '2.2em', justifyContent: 'center',  textAlign: 'center',  color: 'white',}}>MES COMPETENCES</h5>
        <br /><br />
        <br/> <br/> <br/>
          <div
            className="line"
            style={{
              position: 'absolute',
              left: '50%',
              marginTop: '-120px',

              width: '0',
              borderBottom: '2px solid white',
              opacity: '0.3',
              transform: 'translateX(-50%)',
              transition: 'width 2s ease-in-out',
            }}></div>
        <div className="movie-card-container">
          <div className="column">
          {/* Column 1: Backend */}
            <div className="movie-card card-background-3">
              <div className="color-overlay">
                <div className="movie-content">
                  <div className="movie-header">
                    <div className="movie-title"><br /> <br />BACKEND</div>
                  </div>
                  <div className="columns-container">
  <div className="column">
    <p className="movie-desc">
      PHP
      <img src={PhpLogo} alt="Php Logo" className="php-logo" />
      <br /><br />
      Symfony
      <img src={SymfonyLogo} alt="Symfony Logo" className="symfony-logo"  />
      <br /><br />
      Python
      <img src={PythonLogo} alt="Python Logo" className="python-logo"  />
    </p>
  </div>

  <div className="column">
    <p className="movie-desc">
      Django
      <img src={DjangoLogo} alt="Django Logo" className="django-logo"  />
      <br /><br />
      PostgreSQL
      <img src={PostgreSQLLogo} alt="PostgreSQL" className="postgresql-logo"  />
      <br /><br />
      MySQL
      <img src={MySQLLogo} alt="MySQL" className="mysql-logo" />
    </p>
  </div>
</div>
                </div>
              </div>
            </div>
            <br /><br /><br /><br />
            {/* Column 2: Frontend */}
            <div className="movie-card card-background-1">
              <div className="color-overlay">
                <div className="movie-content">
                  <div className="movie-header">
                    <div className="movie-title"><br /><br />FRONTEND</div>
                  </div>
                   <div className="columns-container">
                    <div className="column">
                  <p className="movie-desc">
                    React
                    <img src={ReactLogo} alt="React" className="react-logo"  />
                    <br /><br />
                    Vue.js <img src={VuejsLogo} alt="Vuejs" className="vuejs-logo"  />
                    <br /><br />
                    Bulma <img src={BulmaLogo} alt="Bulma" className="bulma-logo"  />


                    </p></div>


                  <div className="column">
                  <p className="movie-desc">
                    HTML5 <img src={Html5Logo} alt="html5" className="html5-logo" />
                    <br /><br />
                    CSS3 <img src={Css3Logo} alt="css3" className="css3-logo" />
                    <br /><br />
                    Javascript <img src={JavascriptLogo} alt="javascript" className="javascript-logo" />

                    </p></div>
              </div>
            </div>
            </div></div></div>
            <br /><br /><br /><br /><br /><br /><br /><br />
            <div className="column">
            {/* Column 3: Soft Skills */}
            <div className="movie-card card-background-2">
              <div className="color-overlay">
                <div className="movie-content">
                  <div className="movie-header">
                    <div className="movie-title"><br /> <br /> SOFT SKILLS</div>
                  </div>
                   <div className="columns-container">
                    <div className="column">
                  <p className="movie-desc">
                  🔄 Ecoute <br /><br />

                     🌐 Adaptabilité<br /><br />
                    🎨 Créativité</p>
                  </div>
                   <div className="column">
                  <p className="movie-desc">
                  </p>
                  </div></div></div>

              </div>
            </div>
            <br /><br /><br />

          <div className="column" style={{marginTop: '23px',}}>


            {/* Column 4: Tools */}
            <div className="movie-card card-background-4">
              <div className="color-overlay">
                <div className="movie-content">
                  <div className="movie-header">
                    <div className="movie-title"><br /> <br /> OUTILS</div>
                  </div>
                  <div className="columns-container">
                    <div className="column">
                  <p className="movie-desc">
                    PyCharm  <img src={PyCharmLogo} alt="PyCharm" className="logo" style={{ width: '30', marginLeft: '155px', backgroundColor: 'transparent', border: '0', boxShadow: 'none' }} />
                    <br /> <br />Visual Studio Code
                    <img src={VSCodeLogo} alt="VSCode" className="vscode-logo" /><br /><br /></p></div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br /><br /><br /><br />
      </div></div></div>

  );
};

export default CompetencePage;